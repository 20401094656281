import { Typography } from "@mui/material";
import { Logo } from "Components/Layout/Logo";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { AppRouting, getPath } from "Utils/UrlUtils";
import * as React from "react";
import styled from "styled-components";

type Props = {
  isVisible: boolean;
  leftOffset?: number;
};

const StyledWrapper = styled(StyledFlex)<{
  $isVisible: boolean;
  $leftOffset?: number;
}>`
  width: 100%;

  ${props => props.theme.breakpoints.down("md")} {
    padding-left: ${props => props.$leftOffset ?? props.theme.spacing(8)};

    ${props =>
      !props.$isVisible &&
      `
        height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
    `}
  }
`;

const StyledUnstyledLink = styled(UnstyledLink)`
  color: ${props => props.theme.palette.text.primary};
`;

const AppBar: React.FunctionComponent<Props> = props => {
  const { isVisible, leftOffset } = props;

  return (
    <>
      <StyledUnstyledLink to={getPath(AppRouting.Dashboard)}>
        <StyledWrapper
          $alignItems={"center"}
          $isVisible={isVisible}
          $leftOffset={leftOffset}
        >
          <Logo size={38}></Logo>
          <Typography fontWeight={700} fontSize={15} marginLeft={1}>
            Metaff
          </Typography>
        </StyledWrapper>
      </StyledUnstyledLink>
    </>
  );
};

export { AppBar };
