import { Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getCodeListCollectionAsync } from "State/CodeLists/Collection/GetCodeListCollection";
import { FunctionComponent, useEffect } from "react";

export const DASHBOARD_TRANSACTION_LIMIT = 2;

export const DashboardPage: FunctionComponent = _ => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCodeListCollectionAsync.request());
  }, [dispatch]);

  return (
    <AuthenticatedLayout
      title="Přehled"
      isLogoShown={true}
      topGrayBarHeight={1000}
    >
      <StyledPageWrapper>
        <div className="left-content">
          <br />
          <br />
          <br />
          <Typography>Zdravím, díky že používáte šablonu. 🧠</Typography>
          <br />
          <a
            href="https://blogic.atlassian.net/wiki/spaces/BT/pages/883327011/BLogic+ablona"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pro více informací o tom, jak šablona funguje, navštivte Confluence
          </a>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default DashboardPage;
