import * as React from "react";
import { RouteProps, useRoutes } from "react-router-dom";
import { AuthenticatedRoute } from "Routes/AuthenticatedRoute";
import { useAppSelector } from "State/Store";
import { AppRouting, getRoute } from "Utils/UrlUtils";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import { Error404Page } from "Pages/Errors/Error404Page";
import { Error403Page } from "Pages/Errors/Error403Page";
import { DashboardPage } from "Pages/Dashboard/DashboardPage";
import { GoogleCallbackPage } from "Pages/Auth/GoogleCallbackPage";
import { EmailVerificationPage } from "Pages/Verifications/EmailVerificationPage";
import { AwaitingEmailVerificationPage } from "Pages/Verifications/AwaitingEmailVerificationPage";
import { ForgottenPasswordPage } from "Pages/Auth/ForgottenPasswordPage";

const SignInPage = reactLazyWithRetry(() => import("Pages/Auth/SignInPage"));

const SignUpPage = reactLazyWithRetry(() => import("Pages/Auth/SignUpPage"));

const Routes: React.FunctionComponent<RouteProps> = _ => {
  const isAuthenticated = useAppSelector(e => e.auth.session.isAuthenticated);

  const appRoutes = [
    {
      path: getRoute(AppRouting.Welcome),
      element: isAuthenticated ? <DashboardPage /> : <SignInPage />,
    },
    {
      path: getRoute(AppRouting.SignUp),
      element: <SignUpPage />,
    },
    {
      path: getRoute(AppRouting.SignIn),
      element: <SignInPage />,
    },
    {
      path: getRoute(AppRouting.GoogleCallback),
      element: <GoogleCallbackPage />,
    },
    {
      path: getRoute(AppRouting.ForgottenPassword),
      element: <ForgottenPasswordPage />,
    },
    {
      path: getRoute(AppRouting.Dashboard),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <DashboardPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.EmailVerification),
      element: <EmailVerificationPage />,
    },
    {
      path: getRoute(AppRouting.AwaitingEmailVerification),
      element: <AwaitingEmailVerificationPage />,
    },
    {
      path: getRoute(AppRouting.Error403),
      element: <Error403Page />,
    },
    { path: "*", element: <Error404Page /> },
  ];

  return useRoutes(appRoutes);
};

export { Routes };
