import { Typography } from "@mui/material";
import { Logo } from "Components/Layout/Logo";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  padding-bottom: ${props => props.theme.spacing(4)};
  padding-top: ${props => props.theme.spacing(6)};
`;

export const UnauthenticatedHeader: React.FunctionComponent = _ => {
  return (
    <StyledWrapper>
      <Logo></Logo>
      <Typography component="h1" variant="h1">
        {"Metaff"}
      </Typography>
    </StyledWrapper>
  );
};
