import MenuIcon from "@mui/icons-material/Menu";
import { Box, Drawer, Typography } from "@mui/material";
import { Avatar } from "Components/Layout/Avatar";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { ExpandArrowIcon } from "Components/Shared/Icons";
import { AccessRightCodes } from "Models/AccessRightCodes";
import * as React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { resetSession } from "State/Auth/Session/SessionSlice";
import { useAppSelector } from "State/Store";
import styled, { css } from "styled-components";
import { AppRouting, getPath } from "Utils/UrlUtils";

const drawerWidth = 337;

const StyledWrapper = styled.div``;

const StyledMenuIconWrapper = styled(Box)<{ $isVisible: boolean }>`
  position: absolute;
  left: 5px;
  top: 10px;
  z-index: 1;

  ${props => props.theme.breakpoints.down("md")} {
    ${props =>
      !props.$isVisible &&
      `
      display: none;
    `}
  }
`;

const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${drawerWidth}px;
  height: ${props => props.theme.vh(100)};
  background-color: ${props => props.theme.colors.gray};
  box-shadow: inset -1px 0 6px 0 rgba(0, 0, 0, 0.13);
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: auto;
`;

const StyledList = styled.div`
  margin-top: ${props => props.theme.spacing(6)};
  display: flex;
  flex-direction: column;
  & > div {
    border-top: 1px solid ${props => props.theme.colors.border};
    &:nth-last-child(4) {
      border-bottom: 1px solid ${props => props.theme.colors.border};
    }
    &:nth-last-child(3) {
      border-top: 1px solid ${props => props.theme.colors.border};
      margin-top: auto;
      align-items: flex-end;
    }
  }
`;

const StyledLink = css`
  text-decoration: none;
  color: ${props => props.theme.palette.text.primary};

  &.active {
    color: ${props => props.theme.palette.primary.main};
  }
  ${props => props.theme.breakpoints.up("md")} {
    &:hover {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  ${StyledLink}
`;

const StyledAnchor = styled.a`
  ${StyledLink}
`;

const StyledItem = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;

  padding: ${props => props.theme.spacing(1.8)};
  padding-left: ${props => props.theme.spacing(5)};
  ${props =>
    props.isDisabled &&
    `
    color: ${props.theme.palette.text.secondary};
    opacity: 0.5;
  `}

  p {
    font-size: 16px;
    font-weight: 500;
  }
`;

const StyledRightIcon = styled(ExpandArrowIcon)`
  width: 16px;
  height: auto;
  position: absolute;
  right: 25px;
  transform: rotate(-90deg);
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: ${props => props.theme.palette.text.primary};
  width: 24px;
  height: auto;
`;

type ApplicationLink = {
  text: JSX.Element;
  to: string;
  accessRightCode: string | null;
  isDisabled?: boolean;
  emptySpaceSize?: number;
  isExternalLink?: boolean;
};

type Props = {
  isVisible: boolean;
};

const Menu: React.FunctionComponent<Props> = props => {
  const accessRightCodes =
    useAppSelector(e => e.auth.session.user?.accessRightCodes) || [];
  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const links: ApplicationLink[] = [
    {
      text: (
        <StyledItem>
          <Typography>{"Přehled"}</Typography>
        </StyledItem>
      ),
      to: getPath(AppRouting.Dashboard),
      accessRightCode: AccessRightCodes.Dashboard,
    },
    {
      text: (
        <StyledItem
          onClick={() => {
            dispatch(resetSession({}));
          }}
        >
          <Typography>Odhlásit</Typography>
          <StyledRightIcon></StyledRightIcon>
        </StyledItem>
      ),
      to: "/",
      accessRightCode: null,
    },
  ];

  const menu = (
    <StyledMenu>
      <Avatar />

      <StyledList>
        {links
          .filter(
            ({ accessRightCode }) =>
              !accessRightCode ||
              accessRightCodes.some(e => e === accessRightCode),
          )
          .map((l, i) => {
            if (!!l.emptySpaceSize) {
              return (
                <Box
                  key={i}
                  sx={{ height: `calc(var(--vh) * ${l.emptySpaceSize * 6})` }}
                ></Box>
              );
            } else {
              return l.isDisabled === true ? (
                <div key={l.to}>{l.text}</div>
              ) : (
                <div key={l.to}>
                  {l.isExternalLink ? (
                    <StyledAnchor href={l.to} target="_blank">
                      {l.text}
                    </StyledAnchor>
                  ) : (
                    <StyledNavLink to={{ pathname: l.to }}>
                      {l.text}
                    </StyledNavLink>
                  )}
                </div>
              );
            }
          })}
      </StyledList>
    </StyledMenu>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <StyledWrapper>
        <StyledMenuIconWrapper
          $isVisible={props.isVisible}
          sx={{
            display: {
              sm: "block",
              md: "none",
            },
          }}
        >
          <BlDefaultButton onClick={handleDrawerToggle}>
            <StyledMenuIcon />
          </BlDefaultButton>
        </StyledMenuIconWrapper>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            border: "none",
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              boxShadow: "none",
            },
          }}
        >
          {menu}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            height: "100%",
            border: "none",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              position: "unset",
              border: "none",
              boxShadow: "none",
            },
          }}
          open
        >
          {menu}
        </Drawer>
      </StyledWrapper>
    </>
  );
};

export { Menu };
