import { TextFieldProps } from "@mui/material";
import { StyledFormControl } from "Components/Shared/FormStyles";
import { BlTextInput } from "Components/Shared/Inputs/BlTextInput";
import { get } from "lodash-es";
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
} from "react-hook-form";

type Props<FormType extends FieldValues> = TextFieldProps & {
  name: FieldPath<FormType>;
  label?: string;
  control: Control<FormType, object>;
  errors: FieldErrors<FormType>;
  formInfo?: { [key: string]: string };
  maxLength?: number;
  disabled?: boolean;
  mask?: string | NumberConstructor | RegExp;
  type?: string;
  max?: number;
  min?: number;
  scale?: number;
  isNumber?: boolean;
  formControlClass?: "long" | "medium" | "tiny";
  unit?: string;
  inputEndAdornment?: JSX.Element;
};

export const BlFormInput = <T extends object>(props: Props<T>) => {
  const {
    name,
    control,
    errors,
    formInfo,
    maxLength,
    label,
    disabled,
    mask,
    type,
    max,
    min,
    scale,
    isNumber,
    formControlClass,
    unit,
    ...rest
  } = props;

  const transform =
    isNumber || type === "number"
      ? {
          input: (value: number) =>
            !value || isNaN(value) || value === 0 ? "" : value.toString(),
          output: (e: { target: { value: string } }) => {
            const output = Number(e.target.value);
            return isNaN(output) ? 0 : output;
          },
        }
      : {
          input: (value: any) =>
            value === null || value === undefined ? "" : value,
          output: (e: { target: { value: string } }) => {
            return e.target.value;
          },
        };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, onBlur } }) => {
          return (
            <StyledFormControl
              className={`${formControlClass} ${!label ? "no-label" : ""}`}
            >
              <BlTextInput
                value={transform.input(value as any)}
                name={name.toString()}
                label={label}
                fieldError={get(errors, name as string)}
                maxLength={maxLength}
                disabled={disabled}
                onBlur={onBlur}
                mask={mask}
                type={type ?? "text"}
                max={max}
                min={min}
                scale={scale}
                unit={unit}
                onInputChange={onChange}
                onMaskInputChange={v => {
                  onChange(isNumber ? Number(v) : v);
                }}
                {...rest}
              />
            </StyledFormControl>
          );
        }}
      />
    </>
  );
};
