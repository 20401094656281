import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AvatarIcon } from "Components/Shared/Icons";
import React from "react";
import styled from "styled-components";
import { default as MuiAvatar } from "@mui/material/Avatar";
import { useAppSelector } from "State/Store";

const StyledWrapper = styled.div`
  display: flex;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: ${props => props.theme.spacing(5, 4)};
  gap: ${props => props.theme.spacing(3)};
`;

const StyledAvatar = styled(AvatarIcon)`
  width: 32px;
  height: auto;
`;

const truncate = (input: string) => {
  const threshold = 22;
  if (input.length <= threshold) {
    return input;
  }

  const shortened = input.split("@")[0];

  return shortened.length > threshold
    ? `${shortened.substring(0, threshold)}...`
    : shortened;
};

export const Avatar: React.FunctionComponent = _ => {
  const theme = useTheme();
  const { user } = useAppSelector(e => e.auth.session);

  const name =
    !!user?.firstName && !!user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : `${user?.login}`;

  return (
    <StyledWrapper>
      {user?.profilePicture && <MuiAvatar src={user.profilePicture} />}
      {!user?.profilePicture && (
        <StyledAvatar color={theme.palette.text.primary}></StyledAvatar>
      )}
      <div>
        <Typography variant={"h3"}>{truncate(name)}</Typography>
        {user && user.login !== name && (
          <Typography>{truncate(user.login)}</Typography>
        )}
      </div>
    </StyledWrapper>
  );
};
