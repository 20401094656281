import "./index.css";
import App from "./App";
import { logError } from "ErrorService";
import * as Sentry from "@sentry/react";
import TagManager from "react-gtm-module";
import { createRoot } from "react-dom/client";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { parseSentryUserFromJWT } from "Utils/TrackingUtils";
import { ApplicationErrorComponent } from "Components/Errors/ApplicationErrorComponent";

if (import.meta.env.VITE_SENTRY_IS_ENABLED === "true") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
      new Sentry.HttpContext(),
      new Sentry.Dedupe(),
    ],
    environment: import.meta.env.VITE_VERSION as string,
    tracePropagationTargets: [/Metaff/, "localhost"],
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.extra?.doNotShowDialog !== true) {
        Sentry.showReportDialog({ eventId: event.event_id, lang: "cs" });
      }
      return event;
    },
    initialScope: scope => {
      const user = parseSentryUserFromJWT();
      if (user) {
        scope.setUser(user);
        scope.setTag("user.login", user.username);
      }

      return scope;
    },
    autoSessionTracking: false,
  });
}

if (import.meta.env.VITE_GOOGLE_TAG_MANAGER_IS_ENABLED === "true") {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_CODE as string,
  };
  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary
    fallback={errorInfo => (
      <ApplicationErrorComponent error={errorInfo.error} />
    )}
    showDialog={true}
    dialogOptions={{
      lang: "cs",
    }}
  >
    <Sentry.Profiler name="investika-app-react">
      <App />
    </Sentry.Profiler>
  </Sentry.ErrorBoundary>,
);

window.addEventListener("error", function (event) {
  logError(event.error);
});

const setVhUnit = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
setVhUnit();
window.addEventListener("resize", () => {
  setVhUnit();
});
