import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FieldErrorProps, useFieldError } from "Hooks/useFieldError";
import * as React from "react";
import { IMaskMixin } from "react-imask";
import styled from "styled-components";
import { isNoU } from "Utils/ObjectUtils";

type Props = TextFieldProps &
  FieldErrorProps & {
    label?: string;
    name?: string;
    mask?: string | NumberConstructor | RegExp;
    unmask?: boolean | "typed";
    max?: number;
    min?: number;
    onMaskInputChange?: (val: string) => void;
    onInputChange?:
      | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined;
    inputEndAdornment?: JSX.Element;
    maxLength?: number;
    scale?: number;
    unit?: string;
  };

const StyledInput = styled(TextField)`
  margin-top: ${props => props.theme.spacing(1)};

  .MuiInputBase-input {
    padding-bottom: ${props => props.theme.spacing(1)};
    color: ${props => props.theme.palette.text.primary};
    font-size: 18px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover &:-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px
        ${props => props.theme.palette.background.default} inset;
      color: ${props => props.theme.palette.text.primary};
    }
    &::placeholder {
      color: ${props => props.theme.colors.gray};
      opacity: 1;
    }
  }

  .MuiInputBase-root {
    &::before {
      border-bottom: 1px solid ${props => props.theme.colors.border};
    }
  }

  .MuiInputLabel-root {
    font-size: 18px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${props => props.theme.colors.border};
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiFormHelperText-root {
    margin: 0;
    min-height: 20px;
  }

  .Mui-disabled {
    &.MuiInputBase-formControl {
      background-color: ${props => props.theme.colors.gray}80;

      .MuiInputBase-input {
        color: ${props => props.theme.palette.text.primary}80;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.colors.border};
      }
    }
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  flex-flow: column;

  .input-wrapper {
    position: relative;
  }
`;

const StyledUnit = styled.div`
  position: absolute;
  pointer-events: none;
  top: calc(50% - 20px);
  right: 20px;
  opacity: 0.2;
`;

const MaskedInput = IMaskMixin(({ ref, ...props }: Props) => {
  return <StyledInput {...props} ref={ref} />;
}) as any;

export const BlTextInput: React.FunctionComponent<Props> = props => {
  const { hasError, errorMessage, originalProps } = useFieldError(props);
  const {
    label,
    name,
    mask,
    unmask,
    max,
    min,
    onMaskInputChange,
    onInputChange,
    value,
    inputEndAdornment,
    maxLength,
    scale,
    unit,
    ...rest
  } = originalProps;

  const handleMaskInputChange = (nextValue: string) => {
    onMaskInputChange && onMaskInputChange(nextValue);
  };

  return (
    <StyledInputWrapper>
      <div className={"input-wrapper"}>
        {isNoU(mask) ? (
          <StyledInput
            name={name}
            id={name}
            variant={"standard"}
            label={label}
            fullWidth
            error={hasError}
            value={value}
            helperText={errorMessage ?? " "}
            InputProps={{
              endAdornment: inputEndAdornment,
            }}
            inputProps={{ maxLength: maxLength }}
            {...rest}
            onChange={onInputChange}
          ></StyledInput>
        ) : (
          <MaskedInput
            mask={mask}
            unmask={unmask ?? true}
            radix=","
            mapToRadix={["."]}
            thousandsSeparator={" "}
            max={max ?? Number.MAX_SAFE_INTEGER}
            min={min ?? Number.MIN_SAFE_INTEGER}
            value={value}
            name={name}
            id={name}
            variant={"standard"}
            fullWidth
            error={hasError}
            helperText={errorMessage ?? " "}
            onAccept={handleMaskInputChange}
            scale={scale}
            label={label}
            InputProps={{
              endAdornment: inputEndAdornment,
            }}
            {...rest}
          ></MaskedInput>
        )}
        {unit && <StyledUnit>{unit}</StyledUnit>}
      </div>
    </StyledInputWrapper>
  );
};
