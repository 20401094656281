import styled from "styled-components";

const StyledFormRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${props => props.theme.spacing(5, 4)};
  padding-left: ${props => props.theme.spacing(20)};
  margin-top: ${props => props.theme.spacing(1)};

  .checkbox {
    margin-top: ${props => props.theme.spacing(7)};
  }

  label {
    white-space: nowrap;
  }
`;

const StyledFormTitle = styled.div`
  margin: ${props => props.theme.spacing(5, 0)};
  text-transform: uppercase;
`;

const StyledFormControl = styled.div`
  &.long {
    flex: 0 0 360px;
    max-width: 360px;
  }

  &.medium {
    flex: 0 0 170px;
    max-width: 170px;
  }

  &.tiny {
    flex: 0 0 85px;
    max-width: 85px;
  }

  &.no-label {
    padding-top: 26px;
  }
`;

const StyledFormLabelWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing(1)};
  display: flex;
  align-items: center;
  z-index: 1;

  label {
    display: flex;
    align-items: center;
    font-weight: 300;
    margin: 0;
    color: ${props => props.theme.palette.text.primary};
  }
`;
const StyledFormInput = styled.div`
  & > div {
    display: flex;
  }
`;

const StyledValidationText = styled.div`
  font-size: 12px;
  color: ${props => props.theme.palette.error.main};
  min-height: 20px;
`;

export {
  StyledFormControl,
  StyledFormInput,
  StyledFormLabelWrapper,
  StyledFormRow,
  StyledFormTitle,
  StyledValidationText,
};
