import * as React from "react";
export const LogoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = { ...props, color: props.color };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={91.331}
      height={92.036}
      viewBox="0 0 91.331 92.036"
      {...props}
    >
      <g transform="translate(-0.671 -0.182)">
        <path
          d="M33.4.258,70.1,18.649a2.45,2.45,0,0,1,.276,4.216L43.2,41.288a2.438,2.438,0,0,1-2.451.167L1.36,21.933a2.45,2.45,0,0,1-.139-4.313L31.089.328A2.437,2.437,0,0,1,33.4.258Z"
          transform="translate(11.986 62.726) rotate(-45)"
          fill="#40c0f2"
          fillRule="evenodd"
        />
        <path
          d="M33.4.258,70.1,18.649a2.45,2.45,0,0,1,.276,4.216L43.2,41.288a2.438,2.438,0,0,1-2.451.167L1.36,21.932a2.45,2.45,0,0,1-.139-4.313L31.089.328A2.438,2.438,0,0,1,33.4.258Z"
          transform="translate(0.671 50.706) rotate(-45)"
          fill="#70f0a6"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
