import { ColorsType } from "Components/Layout/Themes/Themes";

export const Colors: ColorsType = {
  PrimaryMain: "#40C0F2",
  PrimaryDark: "#281a39",
  PrimaryText: "#ffffff",
  SecondaryMain: "#70f0a6",
  SecondaryDark: "#70f0a6",
  SecondaryText: "#252525",
  Body: "#ffffff",
  Red: "#E44E4E",
  RedDark: "#E54D4D",
  Warning: "#F7B500",
  Green: "#70f0a6",
  Text: "#252525",
  TextLight: "#69696E",
  Black: "#000000",
  White: "#ffffff",
  Gray: "#FAFAFA",
  Border: "#DDDDDD",
};
