// import {
//   ResetPasswordCommandResult,
//   ResetPasswordCommand,
//   postAuthPasswordReset,
// } from "Api/Api";
// import { createAction, createAsyncAction } from "typesafe-actions";
// import { put, takeLatest } from "redux-saga/effects";
// import { getType } from "typesafe-actions";
// import { call } from "typed-redux-saga";
// import { ResetPasswordStep } from "State/Auth/Models/AuthStateModels";

// export const resetPasswordAsync = createAsyncAction(
//   "@auth/RESET_PASSWORD_REQUEST",
//   "@auth/RESET_PASSWORD_SUCCESS",
//   "@auth/RESET_PASSWORD_FAILURE",
// )<ResetPasswordCommand, ResetPasswordCommandResult, Error>();

// export const setPasswordResetStep = createAction(
//   "@auth/SET_RESET_PASSWORD_STEP",
// )<ResetPasswordStep>();

// function* resetPassword(
//   action: ReturnType<typeof resetPasswordAsync.request>,
// ): Generator {
//   try {
//     const response = yield* call(postAuthPasswordReset, action.payload);
//     if (response.status === 200) {
//       yield put(resetPasswordAsync.success(response.data));
//     } else {
//       yield put(resetPasswordAsync.failure(new Error()));
//     }
//   } catch (err) {
//     yield put(resetPasswordAsync.failure(err as Error));
//   }
// }
// export function* resetPasswordSaga() {
//   yield takeLatest(getType(resetPasswordAsync.request), resetPassword);
// }

import {
  ResetPasswordCommand,
  ResetPasswordCommandResult,
  postAuthPasswordReset,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";

export const resetPasswordAsync = createAsyncAction(
  "@auth/RESET_PASSWORD_REQUEST",
  "@auth/RESET_PASSWORD_RESPONSE",
  "@auth/RESET_PASSWORD_FAILURE",
)<ResetPasswordCommand, ResetPasswordCommandResult, Error>();

function* resetPassword(
  action: ReturnType<typeof resetPasswordAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postAuthPasswordReset,
      action.payload,
    );

    if (status === 200) {
      yield put(resetPasswordAsync.success(data));
      return;
    }

    yield put(resetPasswordAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(resetPasswordAsync.failure(err as Error));
  }
}
export function* resetPasswordSaga() {
  yield takeLatest(getType(resetPasswordAsync.request), resetPassword);
}

export enum ResetPasswordStep {
  Email,
  Success,
}

type ResetSliceState = {
  isLoading: boolean;
  error: Error | null;
  step: ResetPasswordStep;
};

const initialState: ResetSliceState = {
  isLoading: false,
  error: null,
  step: ResetPasswordStep.Email,
};

export const resetPasswordSlice = createSlice({
  initialState,
  name: "resetPassword",
  reducers: {
    setResetPasswordStep: (state, action: PayloadAction<ResetPasswordStep>) => {
      state.step = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getType(resetPasswordAsync.request),
      (state, action: ReturnType<typeof resetPasswordAsync.request>) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(resetPasswordAsync.success),
      (state, action: ReturnType<typeof resetPasswordAsync.success>) => {
        state.isLoading = false;
        state.step = ResetPasswordStep.Success;
      },
    );
    builder.addCase(
      getType(resetPasswordAsync.failure),
      (state, action: ReturnType<typeof resetPasswordAsync.failure>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});

export const { setResetPasswordStep } = resetPasswordSlice.actions;
