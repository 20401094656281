import * as React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CzechResourcesDictionary } from "Translations/CzechResourcesDictionary";
import { Routes } from "Routes/Routes";
import { Layout } from "Components/Layout/Layout";
import { Provider } from "react-redux";
import appStore from "State/Store";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import { configureApiCalls } from "Api/Api";
// import { resetUser, extendSignOutDate } from "State/Auth/AuthReducer";
import { BrowserRouter } from "react-router-dom";
import { ModuleLoadingFallback } from "Components/Layout/ModuleLoadingFallback";
import { JWT_KEY } from "Utils/AuthUtils";
import { initializeHotjar } from "Utils/HotjarUtils";
import { trackUserIdentification } from "Utils/TrackingUtils";
import { useYupLocale } from "Hooks/useYupLocale";
import {
  extendSignOutDate,
  resetSession,
} from "State/Auth/Session/SessionSlice";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: CzechResourcesDictionary,
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

initializeHotjar();

function App() {
  useYupLocale();

  configureApiCalls({
    jwtKey: JWT_KEY,
    onResponse: r => {
      if (r.status === 401) {
        appStore.store.dispatch(resetSession({}));
      } else {
        appStore.store.dispatch(extendSignOutDate());
        trackUserIdentification();
      }
    },
  });

  return (
    <Provider store={appStore.store}>
      <PersistGate loading={null} persistor={appStore.persistor}>
        <BrowserRouter>
          <Layout>
            <React.Suspense fallback={<ModuleLoadingFallback />}>
              <Routes />
            </React.Suspense>
          </Layout>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
