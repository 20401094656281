import { SignInResult, SsoSignInRequest, postAuthSso } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { NavigateFunction } from "react-router";
import { afterSignInSuccess } from "Utils/AuthUtils";

export const signInSsoAsync = createAsyncAction(
  "@auth/SIGN_IN_SSO_REQUEST",
  "@auth/SIGN_IN_SSO_RESPONSE",
  "@auth/SIGN_IN_SSO_FAILURE",
)<
  {
    model: SsoSignInRequest;
    navigate: NavigateFunction;
  },
  SignInResult,
  Error
>();

function* signInSso(
  action: ReturnType<typeof signInSsoAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postAuthSso,
      action.payload.model,
    );

    if (status === 200) {
      yield put(signInSsoAsync.success(data));
      yield* call(afterSignInSuccess, data, action.payload.navigate);

      return;
    }

    yield put(signInSsoAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(signInSsoAsync.failure(err as Error));
  }
}
export function* signInSsoSaga() {
  yield takeLatest(getType(signInSsoAsync.request), signInSso);
}

type SignInSsoSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: SignInSsoSliceState = {
  isLoading: false,
  error: null,
};

export const signInSsoSlice = createSlice({
  initialState,
  name: "signInSso",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(signInSsoAsync.request),
      (state, action: ReturnType<typeof signInSsoAsync.request>) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(signInSsoAsync.success),
      (state, action: ReturnType<typeof signInSsoAsync.success>) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(signInSsoAsync.failure),
      (state, action: ReturnType<typeof signInSsoAsync.failure>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
