import { AppUser } from "State/Auth/Models/AuthStateModels";

// type IdRouteType = string | number;

export const AppRouting = {
  Error403: {
    path: () => `/403`,
  },
  Error404: {
    path: () => `/404`,
  },
  Welcome: {
    path: () => `/`,
  },
  SignUp: {
    path: () => `/sign-up`,
  },
  SignIn: {
    path: () => `/sign-in`,
  },
  GoogleCallback: {
    path: () => `/auth/google`,
  },
  BankIDCallback: {
    path: () => `/auth/bankID`,
  },
  ForgottenPassword: {
    route: `/forgotten-password`,
    path: () => `/forgotten-password`,
  },
  AwaitingEmailVerification: {
    route: `/awaiting-email-verification`,
    path: () => `/awaiting-email-verification`,
  },
  EmailVerification: {
    route: `/email-verification/:token`,
    path: (token: string) => `/email-verification/${encodeURIComponent(token)}`,
  },
  Dashboard: {
    path: () => `/dashboard`,
  },
} as const;

type RouteObject<T extends any[] = any[]> = {
  route?: string;
  path: (...args: T) => string;
};

export function getRoute<T extends RouteObject>(routeObj: T): string {
  return routeObj.route ?? routeObj.path();
}

export function getPath<T extends RouteObject>(
  routeObj: T,
  ...args: Parameters<T["path"]>
): string {
  return routeObj.path(...args);
}

export function resolveStartPage(
  user: AppUser | null,
  unauthenticatedUrl: string | null,
) {
  if (user === null) {
    return getPath(AppRouting.SignIn);
  }

  if (unauthenticatedUrl) {
    return unauthenticatedUrl;
  }
  return getPath(AppRouting.Dashboard);
}

export function getPathnameAndSearch(location: {
  pathname: string;
  search: string;
}) {
  return `${location.pathname}${location.search}`;
}
