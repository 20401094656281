import { ObjPathProxy, createProxy, getPath } from "ts-object-path";
import i18next, { ResourceLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { logError } from "ErrorService";

export interface ResourceDictionary extends ResourceLanguage {
  Common: {
    AppName: string;
    Years: string;
    Years_one: string;
    Years_few: string;
    Years_other: string;
    YearsLabel: string;
    YearsLabel_one: string;
    YearsLabel_few: string;
    YearsLabel_other: string;
    Continue: string;
    Step: string;
    TextCopied: string;
    Yes: string;
    No: string;
    Choose: string;
    Download: string;
    Downloading: string;
    UnsupportedFileType: string;
    Email: string;
    Password: string;
    ExampleLogin: string;
    PiecesShort: string;
    PiecesSuffix: string;
    Active: string;
    Inactive: string;
    Blocked: string;
    Canceled: string;
    Ended: string;
    All: string;
    PrivacyPolicyLink: string;
    Error: string;
  };
  Errors: {
    ApplicationError: {
      Title: string;
      Home: string;
    };
    Error404: {
      Subtitle: string;
      Home: string;
    };
    Error403: {
      Subtitle: string;
    };
    Error500: string;
  };
  SignIn: {
    Error: {
      General: string;
      InvalidLogin: string;
      InvalidCredentials: string;
      ApplicationVerification: string;
      PhoneNumberNotFound: string;
      SmsSendingFailed: string;
      InvalidToken: string;
      AccountNotFound: string;
    };
  };
  SignUp: {
    Error: {
      General: string;
      UserExists: string;
      WeakPassword: string;
    };
  };
  SetPassword: {
    Error: {
      General: string;
      TokenNotFound: string;
      WeakPassword: string;
    };
  };
  Validation: {
    Required: string;
    InvalidEmail: string;
    AlphabetsOnly: string;
    DigitsOnly: string;
    InvalidPhone: string;
    InvalidFormat: string;
    InvalidBankAccount: string;
    InvalidPersonalIdentificationNumber: string;
    Number: {
      Integer: string;
      Min: string;
    };
    String: {
      Min: string;
      Max: string;
      StrictLength: string;
      InvalidFormat: string;
    };
    Array: {
      Min: string;
      Min_one: string;
      Min_few: string;
      Min_other: string;
    };
  };
  Transactions: {
    Type: {
      Buy: string;
      Sell: string;
      Withdrawal: string;
      LeadReward: string;
    };
    Status: {
      Completed: string;
      PendingTrade: string;
      WithdrawRequested: string;
      Withdrawn: string;
    };
  };
  BankIDVerification: {
    Error: {
      General: string;
      BankIDUserInfoError: string;
      VerificationAlreadyExists: string;
    };
  };
}

export const Resources = createProxy<ResourceDictionary>();

export function getResourcePath(
  proxy: ObjPathProxy<ResourceDictionary, string>,
): string {
  const path = getPath(proxy);

  if (!path || path.length === 0) return "";
  if (path.length === 1) return path[0].toString();
  return `${path[0].toString()}:${path.slice(1).join(".")}`;
}

export function useResource() {
  const { t: i18Translation } = useTranslation();

  return {
    t: (
      resourcePath: ObjPathProxy<ResourceDictionary, string>,
      options?: any,
    ) =>
      i18Translation(
        getResourcePath(resourcePath),
        options,
      ) as unknown as string,
  };
}

export function useServerError(
  parentObject: ObjPathProxy<ResourceDictionary, object>,
  fallbackProperty: ObjPathProxy<ResourceDictionary, string>,
) {
  const { t } = useResource();
  return {
    translateError: (code: string | null | undefined) => {
      if (!code) {
        return null;
      }

      const newCode = `${getResourcePath(parentObject as any)}.${code}`;

      const isCodeTranslated = i18next.exists(newCode);

      if (!isCodeTranslated) {
        console.log(`Not found serverError translation for ${newCode}`);
        logError(
          new Error(`Not found serverError translation for ${newCode}`),
          null,
          false,
        );
      }

      const resource = isCodeTranslated
        ? i18next.t(newCode)
        : t(fallbackProperty);

      return resource as string;
    },
  };
}
