import { JWT_KEY } from "Utils/AuthUtils";

export function setToken(result: { token?: string | null | undefined }) {
  if (!!result.token) {
    localStorage.setItem(JWT_KEY, `Bearer ${result.token}`);
    return;
  }

  localStorage.removeItem(JWT_KEY);
}
