import { ResourceDictionary } from "./Resources";

export const CzechResourcesDictionary: ResourceDictionary = {
  Common: {
    AppName: "Metaff",
    Years: "{{count}} let",
    Years_one: "{{count}} rok",
    Years_few: "{{count}} roky",
    Years_other: "{{count}} let",
    YearsLabel: "let",
    YearsLabel_one: "rok",
    YearsLabel_few: "roky",
    YearsLabel_other: "let",
    Continue: "Pokračovat",
    Step: "KROK {{actual}} Z {{count}}",
    TextCopied: "Text byl zkopírován do schránky.",
    Yes: "Ano",
    No: "Ne",
    Choose: "Vyberte",
    Download: "Stáhnout",
    Downloading: "Stahování",
    UnsupportedFileType: "Nepodporovaný typ souboru",
    Email: "E-mail",
    Password: "Heslo",
    ExampleLogin: "např. antonin.vomacka@email.cz",
    PiecesShort: "{{count, number}} ks",
    PiecesSuffix: "ks",
    Active: "Aktivní",
    Inactive: "Neaktivní",
    Blocked: "Blokováno",
    Canceled: "Zrušeno",
    Ended: "Ukončeno",
    All: "Vše",
    PrivacyPolicyLink: "Zásadách zpracování osobních údajů.",
    Error:
      "Něco se pokazilo. Omlouváme se za komplikace. Zkuste to prosím znovu za chvíli.",
  },
  Errors: {
    ApplicationError: {
      Title: "Nastala chyba",
      Home: "Domů",
    },
    Error404: {
      Subtitle: "Stránka nebyla nalezena",
      Home: "Domů",
    },
    Error403: {
      Subtitle: "K zobrazení této stránky nemáte přístupová práva",
    },
    Error500: "Při zpracování požadavku nastala chyba.",
  },
  SignIn: {
    Error: {
      General: "Nastala chyba",
      InvalidLogin: "Špatné heslo nebo e-mail",
      InvalidCredentials: "Špatné heslo nebo e-mail",
      ApplicationVerification: "Špatné heslo nebo e-mail",
      PhoneNumberNotFound: "Telefonní číslo nebylo nalezeno",
      SmsSendingFailed: "Zlyhalo posílání SMS ",
      InvalidToken: "Navalidní token",
      AccountNotFound: "Špatné heslo nebo e-mail",
    },
  },
  SignUp: {
    Error: {
      General: "Nastala chyba",
      UserExists: "Účet již existuje",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  Validation: {
    Required: "Vyplňte pole",
    InvalidEmail: "E-mail je nevalidní",
    AlphabetsOnly: "Pro toto pole jsou povoleny pouze písmenka",
    DigitsOnly: "Může obsahovat pouze číslice",
    InvalidPhone: "Telefonní číslo je nevalidní",
    InvalidFormat: "Neplatný formát",
    InvalidBankAccount: "Neplatné číslo účtu",
    InvalidPersonalIdentificationNumber: "Neplatné rodné číslo",
    Number: {
      Integer: "Musí být celé číslo",
      Min: "Musí být větší nebo rovno {{count}}",
    },
    String: {
      Min: "Musí být alespoň {{count}} znaků dlouhé.",
      Max: "Nesmí být delší než {{count}} znaků.",
      StrictLength: "Musí obsahovat {{count}} znaků",
      InvalidFormat: "Neplatný formát",
    },
    Array: {
      Min: "Musí obsahovat alespoň {{count}} položku.",
      Min_one: "Musí být vybrána alespoň {{count}} položka.",
      Min_few: "Musí být vybrány alespoň {{count}} položky.",
      Min_other: "Musí být vybráno alespoň {{count}} položek.",
    },
  },
  Transactions: {
    Type: {
      Buy: "Nákup",
      Sell: "Prodej",
      Withdrawal: "Výběr",
      LeadReward: "Odměna za doporučení",
    },
    Status: {
      Completed: "Provedeno",
      PendingTrade: "Zpracovává se",
      WithdrawRequested: "Probíhá výběr",
      Withdrawn: "Provedeno",
    },
  },
  SetPassword: {
    Error: {
      General: "Nastala chyba.",
      TokenNotFound: "Token je navalidní",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  BankIDVerification: {
    Error: {
      General:
        "Autentifikace přes BankID se nezdařilo. Zkuste to prosím znovu.",
      BankIDUserInfoError: "Nepodařilo se získat data z BankID.",
      VerificationAlreadyExists: "Uživatel z BankID byl již ověřen.",
    },
  },
};
