import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistedAuthReducer, watchAuthSaga } from "State/Auth/AuthReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { logError } from "ErrorService";
import {
  persistedCodeListReducer,
  watchCodeListSaga,
} from "State/CodeLists/CodeListReducer";
import { uploadReducer } from "State/Upload/UploadReducer";
import { all } from "redux-saga/effects";
import { configureStore } from "@reduxjs/toolkit";
import { Prettify } from "Models/TypeExtensions";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { createReduxEnhancer } from "@sentry/react";
import { SentryEnhancerOptions } from "@sentry/react/types/redux";

const combinedReducer = combineReducers({
  auth: persistedAuthReducer(),
  codeList: persistedCodeListReducer(),
  upload: uploadReducer,
});

export type RootStateType = Prettify<ReturnType<typeof combinedReducer>>;
export type AppDispatch = typeof store.dispatch;

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer<RootStateType>(
  persistConfig,
  combinedReducer,
);

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    logError(error, errorInfo);
  },
});

const sentryReduxEnhancer = createReduxEnhancer(
  {} as SentryEnhancerOptions<RootStateType>,
);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [sagaMiddleware],
  enhancers: [sentryReduxEnhancer],
});

function* rootSaga() {
  yield all([watchAuthSaga(), watchCodeListSaga()]);
}

sagaMiddleware.run(rootSaga);

export const buildAppStore = () => {
  let persistor = persistStore(store);
  return { store, persistor };
};

export default buildAppStore();

export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector;
