
/* eslint-disable */
// THIS FILE WAS GENERATED
// ALL CHANGES WILL BE OVERWRITTEN

// ARCHITECTURE START
  type StandardError = globalThis.Error;
  type Error500s = 501 | 502 | 503 | 504 | 505 | 506 | 507 | 508 | 510 | 511;
  type ErrorStatuses = 0 | Error500s;
  export type ErrorResponse = FetchResponse<unknown, ErrorStatuses> 

  export type FetchResponseOfError = {
    data: null;
    error: StandardError;
    status: ErrorStatuses;
    args: any;
  };

  export type FetchResponseOfSuccess<TData, TStatus extends number = 0> = 
  {
    data: TData;
    error: null;
    status: TStatus;
    args: any;
  }

  export type FetchResponse<TData, TStatus extends number = 0> = 
    TStatus extends ErrorStatuses ? FetchResponseOfError: FetchResponseOfSuccess<TData, TStatus>;

  type Configuration = {
    jwtKey: string | undefined;
    onResponse?: (response: FetchResponse<unknown, any>) => void;
  };
  
  let CONFIG: Configuration = {
    jwtKey: undefined,
    onResponse: () => {},
  };
  
  export function configureApiCalls(configuration: Configuration) {
    CONFIG = { ...CONFIG, ...configuration };
  }
  
  async function fetchJson<T extends FetchResponse<unknown, number>>(...args: any): Promise<T> {
    const errorResponse = (error: StandardError, args: any) => {
      const errorResponse = { status: 0, args, data: null, error } satisfies FetchResponse<T>;
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse as unknown as T;
    }

    const errorStatus = (args: any) => {
      const errorResponse = { status: 0, args, data: null, error: new Error("Network error", {cause: status}) } as FetchResponse<T, Error500s>;
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse as unknown as T;
    }

    try {
      const res: Response = await (fetch as any)(...args);
      try {
        const json = await res.json();
        const response = { data: json, status: res.status, args, error: null };
        CONFIG.onResponse && CONFIG.onResponse(response);
        return response as unknown as T;
      }
      catch (error){
        return errorResponse(error as StandardError, args)
      }
    } catch {
      return errorStatus(args);
    }
  }
  
  const updateHeaders = (headers: Headers) => {
    if (!headers.has("Content-Type")) {
      headers.append("Content-Type", "application/json");
    }
    const token = CONFIG.jwtKey
      ? localStorage.getItem(CONFIG.jwtKey as any)
      : undefined;
    if (!headers.has("Authorization") && token) {
      headers.append("Authorization", token);
    }
  };

function apiPost<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  var raw = JSON.stringify(request);
  updateHeaders(headers);
  var requestOptions = {
    method: "POST",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

type ParamsObject = {
  [key: string]: any;
};

function apiGet<TResponse extends FetchResponse<unknown, number>>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";
  const requestOptions = {
    method: "GET",
    headers,
    redirect: "follow",
  };
  return fetchJson<TResponse>(`${url}${maybeQueryString}`, requestOptions);
}

function apiPut<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  updateHeaders(headers);

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "PUT",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

function apiDelete<TResponse extends FetchResponse<unknown, number>>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";

  var requestOptions = {
    method: "DELETE",
    headers,
    redirect: "follow",
  };
  return fetchJson<TResponse>(`${url}${maybeQueryString}`, requestOptions);
}

function apiPatch<TResponse extends FetchResponse<unknown, number>, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  updateHeaders(headers);

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "PATCH",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}
// ARCHITECTURE END

export const API_ROUTES = { 
	putNotificationsUserSettings: "/api/v1/notifications/user-settings",
	getCodeLists: "/api/v1/code-lists",
	postCacheRefresh: "/api/v1/cache/refresh",
	postAuthSignUp: "/api/v1/auth/sign-up",
	postAuthSignIn: "/api/v1/auth/sign-in",
	postAuthEmailVerification: "/api/v1/auth/email-verification",
	postAuthEmailVerificationSend: "/api/v1/auth/email-verification/send",
	postAuthSso: "/api/v1/auth/sso",
	postAuthPasswordReset: "/api/v1/auth/password-reset",
	putAuthPassword: "/api/v1/auth/password"
}

export type ApiProblemDetails = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export type ErrorDetail = {
	code: ErrorCode;
	message?: string | null;
};

export enum ErrorCode {
	Unspecified = "Unspecified",
	OutOfRange = "OutOfRange",
	NotFound = "NotFound",
	Invalid = "Invalid",
	Forbidden = "Forbidden",
	TooManyRequests = "TooManyRequests",
	Conflict = "Conflict",
	NullOrEmpty = "NullOrEmpty",
	Unauthorized = "Unauthorized",
	ExternalProviderNotAvailable = "ExternalProviderNotAvailable"
};

export type ProblemDetails = {
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export type SaveUserNotificationSettingsCommandResult = {};

export type UserNotificationUpdateRequest = {
	isEmailNotificationActive: boolean;
};

export type GetCodeListCollectionQueryResult = {
	countries: CodeListItemDto[];
};

export type CodeListItemDto = {
	id: number;
	code: string;
	name: string;
};

export type SignUpCommandResult = {};

export type ApiProblemDetailsOfSignUpErrorStatus = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum SignUpErrorStatus {
	UserExists = "UserExists"
};

export type ApiProblemDetailsOfPasswordChangeResultStatus = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum PasswordChangeResultStatus {
	PasswordNotMatch = "PasswordNotMatch",
	NullOrEmpty = "NullOrEmpty",
	TooShort = "TooShort",
	Success = "Success"
};

export type SignUpCommand = {
	login: string;
	password: string;
};

export type SignInResult = {
	token: string;
	login: string;
	firstName: string;
	lastName: string;
	userIdentityProviders: IdentityProvider[];
	accessRightCodes: string[];
	isFirstSignIn: boolean;
	profilePicture?: string | null;
};

export enum IdentityProvider {
	Application = "Application",
	Google = "Google",
	BankID = "BankID"
};

export type ApiProblemDetailsOfAuthError = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum AuthError {
	ApplicationVerification = "ApplicationVerification",
	InvalidCredentials = "InvalidCredentials",
	AccountIsDisabled = "AccountIsDisabled",
	AccountNotFound = "AccountNotFound",
	PhoneNumberNotFound = "PhoneNumberNotFound",
	SmsSendingFailed = "SmsSendingFailed",
	InvalidToken = "InvalidToken",
	AccountWaitingForEmailConfirmation = "AccountWaitingForEmailConfirmation"
};

export type SignInCommand = {
	login: string;
	password: string;
};

export type EmailVerificationCommandResult = {
	signInResult: SignInResult;
};

export type ApiProblemDetailsOfEmailVerificationCommandResultStatus = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum EmailVerificationCommandResultStatus {
	TokenNotFound = "TokenNotFound",
	UserNotFound = "UserNotFound"
};

export type EmailVerificationCommand = {
	token: string;
};

export type ResendVerificationEmailCommandResult = {};

export type ResendVerificationEmailCommand = {
	email: string;
};

export type SsoSignInRequest = {
	token: string;
	identityProvider: IdentityProvider;
};

export type ResetPasswordCommandResult = {};

export type ApiProblemDetailsOfResetPasswordCommandResultStatus = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum ResetPasswordCommandResultStatus {
	Fail = "Fail"
};

export type ResetPasswordCommand = {
	login: string;
};

export type SetPasswordCommandResult = {
	signInResult: SignInResult;
};

export type ApiProblemDetailsOfSetPasswordCommandStatus = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
};

export enum SetPasswordCommandStatus {
	TokenNotFound = "TokenNotFound"
};

export type SetPasswordCommand = {
	token: string;
	password: string;
};

const API_URL = import.meta.env.VITE_API_URL;

export type PutNotificationsUserSettingsFetchResponse = 
| FetchResponse<SaveUserNotificationSettingsCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const putNotificationsUserSettings = (requestContract: UserNotificationUpdateRequest, headers = new Headers()): 
	Promise<PutNotificationsUserSettingsFetchResponse> => 
	apiPut(`${API_URL}/api/v1/notifications/user-settings`, requestContract, headers) as Promise<PutNotificationsUserSettingsFetchResponse>;

export type GetCodeListsFetchResponse = 
| FetchResponse<GetCodeListCollectionQueryResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const getCodeLists = (headers = new Headers()): 
	Promise<GetCodeListsFetchResponse> => {
	return apiGet(`${API_URL}/api/v1/code-lists`, headers, {}) as Promise<GetCodeListsFetchResponse>;
}

export type PostCacheRefreshFetchResponse = 
| FetchResponse<void, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 403> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postCacheRefresh = (headers = new Headers()): 
	Promise<PostCacheRefreshFetchResponse> => 
	apiPost(`${API_URL}/api/v1/cache/refresh`, {}, headers) as Promise<PostCacheRefreshFetchResponse>;

export type PostAuthSignUpFetchResponse = 
| FetchResponse<SignUpCommandResult, 201> 
| FetchResponse<ApiProblemDetailsOfPasswordChangeResultStatus, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfSignUpErrorStatus, 409> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthSignUp = (requestContract: SignUpCommand, headers = new Headers()): 
	Promise<PostAuthSignUpFetchResponse> => 
	apiPost(`${API_URL}/api/v1/auth/sign-up`, requestContract, headers) as Promise<PostAuthSignUpFetchResponse>;

export type PostAuthSignInFetchResponse = 
| FetchResponse<SignInResult, 200> 
| FetchResponse<ApiProblemDetailsOfAuthError, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthSignIn = (requestContract: SignInCommand, headers = new Headers()): 
	Promise<PostAuthSignInFetchResponse> => 
	apiPost(`${API_URL}/api/v1/auth/sign-in`, requestContract, headers) as Promise<PostAuthSignInFetchResponse>;

export type PostAuthEmailVerificationFetchResponse = 
| FetchResponse<EmailVerificationCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfEmailVerificationCommandResultStatus, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthEmailVerification = (requestContract: EmailVerificationCommand, headers = new Headers()): 
	Promise<PostAuthEmailVerificationFetchResponse> => 
	apiPost(`${API_URL}/api/v1/auth/email-verification`, requestContract, headers) as Promise<PostAuthEmailVerificationFetchResponse>;

export type PostAuthEmailVerificationSendFetchResponse = 
| FetchResponse<ResendVerificationEmailCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthEmailVerificationSend = (requestContract: ResendVerificationEmailCommand, headers = new Headers()): 
	Promise<PostAuthEmailVerificationSendFetchResponse> => 
	apiPost(`${API_URL}/api/v1/auth/email-verification/send`, requestContract, headers) as Promise<PostAuthEmailVerificationSendFetchResponse>;

export type PostAuthSsoFetchResponse = 
| FetchResponse<SignInResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfAuthError, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthSso = (requestContract: SsoSignInRequest, headers = new Headers()): 
	Promise<PostAuthSsoFetchResponse> => 
	apiPost(`${API_URL}/api/v1/auth/sso`, requestContract, headers) as Promise<PostAuthSsoFetchResponse>;

export type PostAuthPasswordResetFetchResponse = 
| FetchResponse<ResetPasswordCommandResult, 200> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfResetPasswordCommandResultStatus, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const postAuthPasswordReset = (requestContract: ResetPasswordCommand, headers = new Headers()): 
	Promise<PostAuthPasswordResetFetchResponse> => 
	apiPost(`${API_URL}/api/v1/auth/password-reset`, requestContract, headers) as Promise<PostAuthPasswordResetFetchResponse>;

export type PutAuthPasswordFetchResponse = 
| FetchResponse<SetPasswordCommandResult, 200> 
| FetchResponse<ApiProblemDetailsOfPasswordChangeResultStatus, 400> 
| FetchResponse<ApiProblemDetails, 401> 
| FetchResponse<ApiProblemDetailsOfSetPasswordCommandStatus, 404> 
| FetchResponse<ProblemDetails, 500> 
| ErrorResponse;

export const putAuthPassword = (requestContract: SetPasswordCommand, headers = new Headers()): 
	Promise<PutAuthPasswordFetchResponse> => 
	apiPut(`${API_URL}/api/v1/auth/password`, requestContract, headers) as Promise<PutAuthPasswordFetchResponse>;

export const API = { 
	putNotificationsUserSettings,
	getCodeLists,
	postCacheRefresh,
	postAuthSignUp,
	postAuthSignIn,
	postAuthEmailVerification,
	postAuthEmailVerificationSend,
	postAuthSso,
	postAuthPasswordReset,
	putAuthPassword
}

