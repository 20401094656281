import {
  EmailVerificationCommand,
  EmailVerificationCommandResult,
  postAuthEmailVerification,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { NavigateFunction } from "react-router-dom";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { toast } from "react-toastify";
import { afterSignInSuccess } from "Utils/AuthUtils";
import { createSlice } from "@reduxjs/toolkit";
import { signUpAsync } from "State/Auth/SignUp/SignUpSlice";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";

export const verifyEmailAsync = createAsyncAction(
  "@auth/VERIFY_EMAIL_REQUEST",
  "@auth/VERIFY_EMAIL_SUCCESS",
  "@auth/VERIFY_EMAIL_FAILURE",
)<
  { command: EmailVerificationCommand; navigate: NavigateFunction },
  EmailVerificationCommandResult,
  Error
>();

function* verifyEmail(
  action: ReturnType<typeof verifyEmailAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postAuthEmailVerification,
      action.payload.command,
    );

    if (status === 200) {
      yield put(verifyEmailAsync.success(data));

      yield* call(
        afterSignInSuccess,
        data.signInResult,
        action.payload.navigate,
      );

      yield* call(() => action.payload.navigate(getPath(AppRouting.Dashboard)));
      toast.success("E-mail byl úspěšně ověřen.");
    } else {
      yield* call(() => action.payload.navigate("/"));
      yield put(verifyEmailAsync.failure(mapAPIErrorResponse(error ?? data)));
    }
  } catch (err) {
    yield put(verifyEmailAsync.failure(err as Error));
  }
}
export function* verifyEmailSaga() {
  yield takeLatest(getType(verifyEmailAsync.request), verifyEmail);
}

type EmailVerificationSliceState = {
  error: Error | null;
  isLoading: boolean;
  verificationEmail: string;
};

const initialState: EmailVerificationSliceState = {
  error: null,
  isLoading: false,
  verificationEmail: "",
};

export const emailVerificationSlice = createSlice({
  initialState,
  name: "emailVerification",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(signUpAsync.success),
      (state, action: ReturnType<typeof signUpAsync.success>) => {
        state.verificationEmail = action.payload.login;
      },
    );
    builder.addCase(
      getType(verifyEmailAsync.request),
      (state, action: ReturnType<typeof verifyEmailAsync.request>) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(verifyEmailAsync.success),
      (state, action: ReturnType<typeof verifyEmailAsync.success>) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(verifyEmailAsync.failure),
      (state, action: ReturnType<typeof verifyEmailAsync.failure>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
