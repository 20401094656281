import { Copyright } from "Components/Layout/CopyRight";
import * as React from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { AppBar } from "./AppBar";
import { Menu } from "./Menu";

const StyledLayoutWrapper = styled.div`
  display: flex;
`;

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: ${props => props.theme.spacing(5, 0, 0, 12)};

  ${props => props.theme.breakpoints.down("md")} {
    padding: ${props => props.theme.spacing(2, 0)};
    padding-bottom: 0;
  }

  ${props => props.theme.breakpoints.down("lg")} {
    padding: ${props => props.theme.spacing(2)};
  }
`;

const StyledContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`;

const StyledChildrenWrapper = styled.div``;

const StyledCopyright = styled.div`
  margin-left: -${props => props.theme.spacing(12)};

  ${props => props.theme.breakpoints.down("md")} {
    margin-left: 0;
  }
`;

const StyledTopGrayBar = styled.div<{ height: number }>`
  height: ${props => props.height}px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: ${props => props.theme.colors.gray};
`;

type Props = {
  title: string;
  isLogoShown?: boolean;
  topGrayBarHeight?: number;
};

export const AuthenticatedLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();
  const { title, isLogoShown, topGrayBarHeight } = props;

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  return (
    <StyledLayoutWrapper>
      <Menu isVisible={!!isLogoShown}></Menu>
      <StyledWrapper>
        {!!topGrayBarHeight && (
          <StyledTopGrayBar height={topGrayBarHeight}></StyledTopGrayBar>
        )}
        <AppBar isVisible={!!isLogoShown}></AppBar>
        <StyledContent>
          <StyledChildrenWrapper>{props.children}</StyledChildrenWrapper>
          <StyledCopyright>
            <Copyright />
          </StyledCopyright>
        </StyledContent>
      </StyledWrapper>
    </StyledLayoutWrapper>
  );
};
